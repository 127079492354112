import { asyncFunc } from "../common/asyncFunc";
import { apiEndPoints } from "../common/endpoints";
import { setHeaders } from "../common/setHeader";

const url = import.meta.env.VITE__APP_API;
export const ShareProfileApi = (data) => {
    const config = {
      method: "post",
      url: url + apiEndPoints.SHARE_PROFILE.create,
      headers: setHeaders("application/json", true),
      data,
    };
    return asyncFunc(config);
  };


  export const fieldsToShareProfileApi = () => {
    const config = {
      method: "get",
      url: url + apiEndPoints.SHARE_PROFILE.fields_to_share,
      headers: setHeaders("application/json", true),
    };
    return asyncFunc(config);
  };